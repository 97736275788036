var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-action-sheet",
    {
      staticClass: "Header-None",
      attrs: { title: "区域选择" },
      on: { "click-overlay": _vm.overlay },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("van-search", {
            staticStyle: { height: "70px" },
            attrs: {
              shape: "round",
              background: "#e54e17",
              placeholder: "请输入搜索关键词",
            },
            on: { search: _vm.onSearch },
            model: {
              value: _vm.searchKey,
              callback: function ($$v) {
                _vm.searchKey = $$v
              },
              expression: "searchKey",
            },
          }),
          _c(
            "van-list",
            { staticStyle: { height: "330px", "overflow-y": "auto" } },
            [
              _c(
                "van-checkbox-group",
                {
                  attrs: { direction: "horizontal" },
                  on: { change: _vm.areaCheckChange },
                  model: {
                    value: _vm.areaCheckArr,
                    callback: function ($$v) {
                      _vm.areaCheckArr = $$v
                    },
                    expression: "areaCheckArr",
                  },
                },
                _vm._l(_vm.areaData, function (_data, _index) {
                  return _c(
                    "van-cell",
                    { key: _index },
                    [
                      _c(
                        "van-checkbox",
                        {
                          attrs: {
                            "checked-color": "rgb(229, 78, 23)",
                            name: _data,
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_data.nameCn))])]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }