var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AreaSelect" },
    [
      _vm._l(_vm.areaTypeArr, function (item, index) {
        return _c("div", { key: index, staticClass: "AreaSelect-Content" }, [
          _c(
            "div",
            { staticClass: "AC-Top" },
            [
              _c("span", [_vm._v("我可以提供服务的区域")]),
              _c("svg-icon", {
                attrs: { iconClass: index == 0 ? "add" : "jianhao" },
                on: {
                  click: function ($event) {
                    return _vm.addSelect(index)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "AC-Select" },
            [
              _c(
                "van-radio-group",
                {
                  staticStyle: { padding: "10px" },
                  on: {
                    change: function ($event) {
                      return _vm.areaTypeChange($event, index)
                    },
                  },
                  model: {
                    value: item.areaType,
                    callback: function ($$v) {
                      _vm.$set(item, "areaType", $$v)
                    },
                    expression: "item.areaType",
                  },
                },
                _vm._l(item.radioArr, function (radio, rindex) {
                  return _c("van-radio", {
                    key: rindex,
                    attrs: { name: radio.type, "checked-color": "#e54e17" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function () {
                            return [
                              radio.checkData.length == 0
                                ? _c(
                                    "span",
                                    {
                                      style: {
                                        color:
                                          item.areaType == 2
                                            ? "#e54e17"
                                            : "#444242",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " 点击选择" + _vm._s(radio.name) + " "
                                      ),
                                    ]
                                  )
                                : _vm._l(radio.checkData, function (a, i) {
                                    return _c(
                                      "span",
                                      {
                                        key: i,
                                        staticStyle: { color: "#e54e17" },
                                      },
                                      [_vm._v(" " + _vm._s(a.nameCn) + " ")]
                                    )
                                  }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
            ],
            1
          ),
        ])
      }),
      _c("AreaAction", {
        ref: "actionShow",
        on: { getAreaData: _vm.areaData },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }