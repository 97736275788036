<template>
	<div class="AreaSelect">
		<div class="AreaSelect-Content" v-for="(item,index) in areaTypeArr" :key="index">
			<div class="AC-Top">
        <span>我可以提供服务的区域</span>
			  <svg-icon :iconClass="index==0 ? 'add' : 'jianhao'" @click="addSelect(index)"></svg-icon>
			</div>
			<div class="AC-Select">
        <van-radio-group style="padding: 10px;" v-model="item.areaType" @change="areaTypeChange($event,index)">
					<van-radio v-for="(radio,rindex) in item.radioArr" :key="rindex" :name="radio.type" checked-color="#e54e17">
						<template #default>
              <span v-if="radio.checkData.length == 0" :style="{color: item.areaType == 2 ? '#e54e17' : '#444242'}">
								点击选择{{radio.name}}
							</span>
							<span v-else style="color: #e54e17" v-for="(a,i) in radio.checkData" :key="i">
								{{a.nameCn}}
							</span>	
						</template>
					</van-radio>
				</van-radio-group>
			</div>
		</div>
		<AreaAction @getAreaData="areaData" ref="actionShow"></AreaAction>
	</div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import AreaAction from '@/components/Register/AreaAction'
import { mapMutations,mapState } from 'vuex'
export default {
	name: 'AreaSelect',
	components: {
		AreaAction
	},
	// 定义属性
	data() {
		return {
			areaTypeArr: [
				{
					areaType: '',
          radioArr: [
            {
							type: 2,
							name: '区域',
							checkData: []
						},
						{
							type: 0,
							name: '国家',
							checkData: []
						},
						{
							type: 3,
							name: '省份',
							checkData: []
						},
						{
							type: 1,
							name: '城市',
							checkData: []
						}
					]
				}
			],
			currentIndex: -1,
			parentIndex: -1,
			state: 0
		}
	},
	// 计算属性，会监听依赖属性值随之变化
	computed: {
		...mapState('supplier',['supplierInfoData'])
	},
	// 方法集合
	methods: {
		...mapMutations('supplier',['UPDATE_SET_SUPPLIERAREA','DEL_SUPPLIERAREA_TYPE']),
		initAreaTypeArr() {
      return {
					areaType: '',
          radioArr: [
            {
							type: 2,
							name: '区域',
							checkData: []
						},
						{
							type: 0,
							name: '国家',
							checkData: []
						},
						{
							type: 3,
							name: '省份',
							checkData: []
						},
						{
							type: 1,
							name: '城市',
							checkData: []
						}
					]
				}
		},
		init() {
      this.parentIndex = this.$route.query.index
			this.state = this.$route.query.state
		},
		areaTypeChange(e,index) {
			//每次切换清空数据 本组件 数据
			this.areaTypeArr[index].radioArr.map( x=> {
				x.checkData = []
				return x
			})
			this.currentIndex = index
			this.$refs.actionShow.init(e,[])
		},
		addSelect(index) {
			if (index == 0) {
			  this.areaTypeArr.push(this.initAreaTypeArr())
			} else {
				this.areaTypeArr.splice(index,1)
			}
		},
		areaData(e) {
      //数据组装到
			this.areaTypeArr[this.currentIndex].radioArr.map(x => {
				console.log('_data',x.type,e)
				if (x.type === e[0].type) {
					x.checkData = [...new Set([...e.map(x=>JSON.stringify(x))],[...x.checkData.map(x=>JSON.stringify(x))])]
					.map(x => JSON.parse(x))
					return x
				}
			})
		}
	},
	// 生命周期 - 创建完成（可以访问当前this实例）
	created() {
		
	},
	// 生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
		this.init()
		console.log(this.$route.query,'上看到看看打开的');
	},
	beforeCreate() {}, // 生命周期 - 创建之前
	beforeMount() {}, // 生命周期 - 挂载之前
	beforeUpdate() {}, // 生命周期 - 更新之前
	updated() {}, // 生命周期 - 更新之后
	beforeDestroy() {}, // 生命周期 - 销毁之前
	destroyed() {}, // 生命周期 - 销毁完成
	activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
	.AreaSelect {
		padding: 20px;
		.AreaSelect-Content {
			padding-bottom: 20px;
			.AC-Top {
				display: flex;
				>span:nth-child(1) {
					display: flex;
          align-items: center;
					color: rgba(0, 123, 203, 1);
					font-size: 0.32rem;
					font-family: PingFangSC-semiBold;
				}
				justify-content: space-between;
			}
			.AC-Select {
				margin-top: 10px;
				border-radius: 6px 6px 6px 6px;
				background-color: rgba(255, 255, 255, 1);
				box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.11);
				.van-radio {
					border-bottom: 1px solid #F4EEEE;
					height: 35px;
					/deep/ .van-radio__label {
						color: #444242;
						font-size: 0.28px;
						text-align: left;
						font-family: PingFangSC-light;
					}
				}
				.van-radio:last-child {
					border-bottom: none;
				}
			}
		}
	}
</style>