<template>
	<van-action-sheet class="Header-None" v-model="show" title="区域选择" @click-overlay="overlay">
		<div class="content">
			<van-search
				v-model="searchKey"
				style="height: 70px"
				shape="round"
				@search="onSearch"
				background="#e54e17"
				placeholder="请输入搜索关键词"
			/>
			<van-list style="height: 330px;overflow-y: auto;">
				<van-checkbox-group v-model="areaCheckArr" @change="areaCheckChange" direction="horizontal">
					<van-cell v-for="(_data,_index) in areaData" :key="_index">
						<van-checkbox checked-color="rgb(229, 78, 23)" :name="_data">
							<span>{{_data.nameCn}}</span>
						</van-checkbox>
					</van-cell>
				</van-checkbox-group>
			</van-list>
		</div>
	</van-action-sheet>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import ResourceApi from '@/components/Api/resource'
export default {
	name:  '',
	components: {
		
	},
	// 定义属性
	data() {
		return {
			show: false,
			searchKey: '',
			areaCheckArr: [],
			areaData: [],
			areaType: '',
			finalDataValue: []
		}
	},
	// 计算属性，会监听依赖属性值随之变化
	computed: {},
	// 监控data中的数据变化
	watch: {
		searchKey() {
      this._getData(Number(this.areaType),this.searchKey);
		}
	},
	// 方法集合
	methods: {
		init(areaType,finalDataValue) {
			this.show = true
			this.areaType = areaType
			this.finalDataValue = finalDataValue
			this._getData(Number(areaType),'');
		},
		overlay() {
			let _obj = document.getElementsByClassName('van-overlay')[0]
			if (_obj) {
				_obj.style.display = 'none'
			}
			this.$emit("getAreaData",this.finalDataValue)
		},
		onSearch(e) {
		},
		areaCheckChange(e) {
			this.finalDataValue = [...new Set(
				[...(e.map(x=>JSON.stringify(x)))],
				[...(this.finalDataValue.map(x=>JSON.stringify(x)))]
			)].map(b => JSON.parse(b))
			console.log('ok',this.finalDataValue)
		},
		_getData(type,key) {
			let _obj = { page: 1,size: 20,q: { type: type, key: key }}
      new ResourceApi('resource').placeList(_obj).then((result) => {
				if (result.status === 200) {
					this.areaData = result.data.records
				}
			})
		}
	},
	// 生命周期 - 创建完成（可以访问当前this实例）
	created() {
		
	},
	// 生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
		
	},
	beforeCreate() {}, // 生命周期 - 创建之前
	beforeMount() {}, // 生命周期 - 挂载之前
	beforeUpdate() {}, // 生命周期 - 更新之前
	updated() {}, // 生命周期 - 更新之后
	beforeDestroy() {}, // 生命周期 - 销毁之前
	destroyed() {}, // 生命周期 - 销毁完成
	activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
	.Header-None {
		height: 400px;
		/deep/ .van-action-sheet__header {
			display: none;
		}
	}
</style>